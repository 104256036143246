<template>
	<div>
		<Navbar />
		<Header title="Import Contact" excerpt="Import new contacts using excel files manage them in a single place." />

		<!-- CONTENT -->
		<div class="container">
			<div class="row">
				<div class="col-md-8">
					<div class="alert alert-success" v-if="form.events.success">{{ form.events.success }}</div>
					<div class="alert alert-danger" v-if="form.events.error">{{ form.events.error }}</div>
					<form>
						<div class="form-row">
							<div class="col">
								<div class="form-group">
									<label>File</label>
									<input class="form-control" type="file" v-on:change="ProcessCSVFile" />
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="row">
				<div class="col mt-3">
					<button v-on:click="On_Submit" class="btn btn-primary">Import</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import config from "../../../config.json"
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";

export default {
	name: "CreateContacts",
	components: { Header, Navbar },

	data: () => ({
		contact: {
			file: null
		},
		form: {
			events: {
				error: null,
				success: null
			}
		}
	}),

	methods: {

		On_Submit() {

			/**
			 * Reset form errors and success notice because this
			 * is going to be new submission to contact creation
			 * and we don't need any of the previous errors or
			 * anything
			 */
			this.form.success = null
			this.form.error = null

			/**
			 * Submit new contact information to the API and behave
			 * according to the return response from the API
			 */
			let formData = new FormData()
			formData.append("file", this.file)
			fetch(config.api.protocol + "://" + config.api.fqdn + "/v1.0/contacts/import", {
				method: "POST",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") },
				body: formData
			}).then(Response => Response.json()).then(Response => {
				if (Response.status == "success") {
					this.form.events.success = "Contacts imported successfully."
				} else {
					if (Response.status == "failed") {
						this.form.events.error = Response.error.message
					} else {
						this.form.events.error = "Something went wrong"
					}
				}
			})

		},

		ProcessCSVFile(event) {
			this.file = event.target.files[0]
		}

	}
}
</script>
